.toggle1 {
    display: inline-block;
  }
  
  .toggle1 {
    position: relative;
    height: 100px;
    width: 100px;
  }
  
  .toggle1:before {
    box-shadow: 0;
    border-radius: 84.5px;
    background: #fff;
    position: absolute;
    margin-left: -36px;
    margin-top: -36px;
    opacity: 0.2;
    height: 72px;
    width: 72px;
    left: 50%;
    top: 50%;
  }
  
  .toogle1 input:checked~.label1 {
    color:#030a32;
  }
  
  .toggle1 .button1 {
    transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
    box-shadow: 0 15px 25px -4px rgba(0, 0, 0, 0.5), inset 0 -3px 4px -1px rgba(0, 0, 0, 0.2), 0 -10px 15px -1px rgba(255, 255, 255, 0.6), inset 0 3px 4px -1px rgba(255, 255, 255, 0.2), inset 0 0 5px 1px rgba(255, 255, 255, 0.8), inset 0 20px 30px 0 rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    position: absolute;
    background: #eaeaea;
    margin-left: -34.4px;
    margin-top: -34.4px;
    display: block;
    height: 40px;
    width: 100px;
    left: 50%;
    top: 50%;
  }
  
  .toggle1 .label1 {
    transition: color 300ms ease-out;
    line-height: 72px;
    margin-left: 18px;
    width:"100px";
    text-align: center;
    position: absolute;
    font-weight: 700;
    font-size: 18px;
    display: block;
    opacity: 0.9;
    height: 100%;
    width: 100%;
    color:#162936;
  }
  
  .toggle1 input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    z-index: 1;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
  
  .toggle1 input:active ~ .button1 {
    filter: blur(0.5px);
    box-shadow: 0 12px 25px -4px rgba(0, 0, 0, 0.4), inset 0 -8px 30px 1px rgba(255, 255, 255, 0.9), 0 -10px 15px -1px rgba(255, 255, 255, 0.6), inset 0 8px 25px 0 rgba(0, 0, 0, 0.4), inset 0 0 10px 1px rgba(255, 255, 255, 0.6);
  }
  
  .toggle1 input:active ~ .label1 {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.45);
  }
  
  .toggle1 input:checked ~ .button1 {
    filter: blur(0.5px);
    box-shadow: 0 10px 25px -4px rgba(0, 0, 0, 0.4), inset 0 -8px 25px -1px rgba(255, 255, 255, 0.9), 0 -10px 15px -1px rgba(255, 255, 255, 0.6), inset 0 8px 20px 0 rgba(0, 0, 0, 0.2), inset 0 0 5px 1px rgba(255, 255, 255, 0.6);
  }
  
  .toggle1 input:checked ~ .label1 {
    color: rgba(0, 0, 0, 0.8);
  }
  