.button {
    --key-text-color: #969faf;
    --docsearch-key-shadow: inset 0 -2px 0 0 #cdcde6,inset 0 0 1px 1px #fff,0 1px 2px 1px rgba(30,35,90,0.4);
    --docsearch-key-gradient: linear-gradient(-225deg,#d5dbe4,#f8f8f8);
    border: none;
    background: var(--docsearch-key-gradient);
    border-radius: 3px;
    box-shadow: var(--docsearch-key-shadow);
    /* color: var(--key-text-color); */
    color: #162936 !important;
    height: 3.5em;
    width: 100%;
    text-align: center;
    padding: 0.6em;
    margin: 0 0.6em;
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1em;
    min-width: 1em;
    line-height: 1em;
    user-select: none;
  }
  
  