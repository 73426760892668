.toggle {
    display: inline-block;
  }
  
  .toggle {
    position: relative;
    height: 50px;
    width: 50px;
  }
  
  .toggle:before {
    box-shadow: 0;
    border-radius: 84.5px;
    background: #D3D3D3;
    position: absolute;
    margin-left: -36px;
    margin-top: -36px;
    opacity: 0.2;
  }
  
  .toogle input:checked~.label {
    color:#030a32;
  }
  
  .toggle .button {
    transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
    /* box-shadow: 0 15px 25px -4px rgba(255, 255, 255, 0.5), inset 0 -3px 4px -1px rgba(255, 255, 255, 0.2), 0 -10px 15px -1px rgba(255, 255, 255, 0.6), inset 0 3px 4px -1px rgba(255, 255, 255, 0.2), inset 0 0 5px 1px rgba(255, 255, 255, 0.8), inset 0 20px 30px 0 rgba(255, 255, 255, 0.2); */
    position: absolute;
    background:#D3D3D3;
    margin-left: -20px; /* half of the width */
    margin-top: -15px; /* half of the height */
    display: block;
    height: 30px; /* set the desired height */
    width: 40px; /* set the desired width */
    left: 50%;
    top: 50%;
    border-radius: 4px;
  }
  .toggle .label {
    transition: color 300ms ease-out;
    line-height: 58px;
    text-align: center;
    position: absolute;
    font-weight: 700;
    font-size: 32px;
    display: block;
    opacity: 0.9;
    height: 100%;
    width: 100%;
    color:#000;
  } 
  
  
  .toggle input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    z-index: 1;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
  
  .toggle input:active ~ .button {
    filter: blur(0.5px);
    box-shadow: 0 12px 25px -4px rgba(0, 0, 0, 0.4), inset 0 -8px 30px 1px rgba(255, 255, 255, 0.9), 0 -10px 15px -1px rgba(255, 255, 255, 0.6), inset 0 8px 25px 0 rgba(0, 0, 0, 0.4), inset 0 0 10px 1px rgba(255, 255, 255, 0.6);
  }
  
  .toggle input:active ~ .label {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.45);
  }
  
  .toggle input:checked ~ .button {
    filter: blur(0.5px);
    box-shadow: 0 10px 25px -4px rgba(0, 0, 0, 0.4), inset 0 -8px 25px -1px rgba(255, 255, 255, 0.9), 0 -10px 15px -1px rgba(255, 255, 255, 0.6), inset 0 8px 20px 0 rgba(0, 0, 0, 0.2), inset 0 0 5px 1px rgba(255, 255, 255, 0.6);
  }
  
  .toggle input:checked ~ .label {
    color: rgba(0, 0, 0, 0.8);
  }
  