/* OverlayVideo.css */
.overlay-video-container {
  border: 2px solid #E6E6FA;
  position: relative;
  z-index: 99;
}

.overlay-video {
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the overlay color and opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.play-button {
  font-size: 2em;
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.bounding-box {
  position: absolute;
  border: 2px solid rgb(237, 2, 2); /* Adjust the border color and width as needed */
  z-index: 3;
}
