.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.flex-container-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex-display {
  display: flex;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}
.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-evenly {
  justify-content: space-evenly;
}
.justify-content-around {
  justify-content: space-around;
}
.cursor-pointer {
  cursor: pointer;
}

.box-shadow {
  box-shadow: none !important;
}

.width-50 {
  width: 50%;
}
.width-100 {
  width: 100%;
}
.width-80 {
  width: 85%;
}

.height-100 {
  height: 100%;
}
.height-90 {
  height: 90%;
}
.margin-auto {
  margin: auto;
}

.border {
  border: 1px solid #080808;
}

.overflow-hidden {
  overflow: hidden;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.text-underline {
  text-decoration: underline;
}

.text-none {
  text-decoration: none;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-100 {
  opacity: 1;
}
.TextField-without-border-radius fieldset {
  border-radius: 24px;
}
